import * as React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SelectedPage = ({ data, location }) => {
  const title = data.markdownRemark.frontmatter.title
  const image = data.markdownRemark

  if (image.length === 0) {
    return (
      <Layout location={location} title={title}>
        <SEO title="All posts" />
        <p>
          No images found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={title}>
      <SEO title="About" />

      <h1 className="font-serif uppercase text-center">{title}</h1>
      
      <div className="masonry-with-columns">
          {image.frontmatter.gallery.map(gallery => {

          return (
            
            <div className="image">
             <Img fluid={gallery.childImageSharp.fluid} />
             </div>

          )
        })}
     </div>
    </Layout>
  )
}

export default SelectedPage

export const query = graphql`
  {
    markdownRemark(
      fileAbsolutePath: {regex: "/content/pages/"}
      frontmatter: {title: {eq: "Selected"}}
    ) {
        id
      frontmatter {
        title
        gallery {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
          }
        }
      }
    }
  }
`